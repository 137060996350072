import React, { useEffect, useRef, useState } from "react";
import { AuthenticationErrorTypes, useAuth } from "@/services/hooks/useAuth";
import commonStyles from "../styles/Common.module.scss";
import Head from "next/head";
import TextWithLabel from "./common/TextWithLabel/TextWithLabel";
import Link from "next/link";
import { useRouter } from "next/router";
import Modal from "./common/Modal/Modal";
import { UserCredential } from "firebase/auth";
import authStyles from "../styles/Auth.module.scss";
import { auth } from "@/services/firebase";
import { BackgroundGradient, LoadingPage } from "./common";
import { useToast } from "@chakra-ui/react";

interface AuthPageProps {
    labels: PageLabels;
    onSubmit: (email: string, password: string) => Promise<any>;
}

interface PageLabels {
    NAME: string;
    SUBMIT_BUTTON_TEXT: string;
    REDIRECT_PROMPT: string;
    REDIRECT_PROMPT_LINK_TEXT: string;
    REDIRECT_PROMPT_LINK_URL: string;
    NEXT_PAGE?: string;
    WRITEUP?: boolean;
    WRITEUP_TEXT?: string;
    CTA?: boolean;
    CTA_TEXT?: string;
    DISPLAY_FORGOT_PASSWORD: boolean;
}

export default function AuthPage({ labels, onSubmit }: AuthPageProps) {
    const _isMounted = useRef(true);
    const {
        user,
        isLoading,
        isLoggingIn,
        setIsLoggingIn,
        isRegistering,
        setIsRegistering,
        loginWithGoogle,
        loginWithFacebook,
        resetPassword,
        handleAuthChange,
    } = useAuth();
    const router = useRouter();
    const toast = useToast();

    const gradient = "linear-gradient(to bottom, #524f38, #858266 21%, rgba(255, 255, 255, 0))";

    const [email, setEmail] = useState<string>(null);
    const [password, setPassword] = useState<string>(null);
    const [showModal, setShowModal] = useState(false);
    const [showNoteModal, setShowNoteModal] = useState(false);
    const [showLoadingPage, setShowLoadingPage] = useState(false);

    const toggleModal = () => setShowModal((currentValue) => !currentValue);

    const [forgotEmail, setForgotEmail] = useState<string>(null);

    useEffect(() => {
        return () => {
            // ComponentWillUnmount in Class Component
            _isMounted.current = false;
        };
    }, []);

    const _resetPassword = () => {
        resetPassword(forgotEmail)
            .then(() => toggleModal())
            .catch((error) => null);
    };

    const _navigateAfterLogin = (user) => {
        if (user && user !== null) {
            if (labels.NEXT_PAGE) {
                router.push(labels.NEXT_PAGE);
            } else {
                router.push("/onboarding");
            }
        }
    };

    const _loginWith = (provider: Function) => {
        setIsLoggingIn(true);
        provider()
            .then(async (res) => {
                localStorage.setItem("jwt", await auth.currentUser.getIdToken());
                return res.user;
            })
            .then((user) => {
                _navigateAfterLogin(user);
            })
            .catch((err) => {
                if (err.code == AuthenticationErrorTypes.EXISTS_WITH_DIFFERENT_PROVIDER) {
                    if (_isMounted.current) {
                        setIsLoggingIn(false);
                        setShowNoteModal(true);
                    }
                }
            });
    };

    // Handle error scenarios using query params
    useEffect(() => {
        if (router.query.error == "401") {
            toast({
                title: "Please Log In",
                description: "You must first log in with your Vacayou Voyager Club account to see this page.",
                status: "warning",
                duration: 9000,
                isClosable: true,
            });
            if (_isMounted.current) setIsLoggingIn(false);
        } else if (router.query.error == "403") {
            toast({
                title: "Please Upgrade",
                description:
                    "You must first join the Vacayou Voyager Club member to see this page. Please join the club.",
                status: "warning",
                duration: 9000,
                isClosable: true,
            });
            if (_isMounted.current) setIsLoggingIn(false);
        }
    }, [router.query.error]);

    // Determine if loading page should be displayed
    useEffect(() => {
        if (_isMounted.current) {
            if (!isLoggingIn && !isRegistering) {
                setShowLoadingPage(false);
            } else if (isLoggingIn || isRegistering) {
                setShowLoadingPage(true);
            }
        }
    }, [isLoggingIn, isRegistering]);

    useEffect(() => {
        _navigateAfterLogin(user);
    }, [user]);

    if (isLoading || user !== null) {
        return <LoadingPage />;
    }

    if (user === null) {
        return (
            <>
                <Head>
                    <title>{labels.NAME}</title>
                </Head>
                {showLoadingPage && <LoadingPage />}
                <div className={authStyles.authRoot} style={{ display: showLoadingPage ? "none" : "inherit" }}>
                    <div>
                        <BackgroundGradient gradient={gradient} />
                    </div>
                    <div>
                        <h1>{labels.NAME}</h1>
                        {labels.WRITEUP && <p className={authStyles.authDescription}>{labels.WRITEUP_TEXT}</p>}
                        {labels.CTA && (
                            <p className={authStyles.authDescription} style={{ fontWeight: "bold" }}>
                                {labels.CTA_TEXT}
                            </p>
                        )}
                        <button className={commonStyles.googleButton} onClick={() => _loginWith(loginWithGoogle)}>
                            <i className={"fab fa-google"}></i>
                            {labels.SUBMIT_BUTTON_TEXT} with Google
                        </button>
                        <button className={commonStyles.facebookButton} onClick={() => _loginWith(loginWithFacebook)}>
                            <i className={"fab fa-facebook-f"}></i>
                            {labels.SUBMIT_BUTTON_TEXT} with Facebook
                        </button>
                        <div className={authStyles.divisionBlock}>
                            <div>
                                <hr />
                            </div>
                            <div>OR</div>
                            <div>
                                <hr />
                            </div>
                        </div>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                setIsLoggingIn(true);
                                setIsRegistering(true);
                                onSubmit(email, password)
                                    .then((userCredential: UserCredential) => {
                                        if (!_isMounted.current) {
                                            return;
                                        }
                                        const user = userCredential.user;
                                        handleAuthChange(user);
                                        setIsLoggingIn(false);
                                        setIsRegistering(false);
                                        _navigateAfterLogin(user);
                                    })
                                    .catch((error) => {
                                        if (!_isMounted.current) return;
                                        setIsLoggingIn(false);
                                        setIsRegistering(false);
                                        if (error.message == "Firebase: Error (auth/email-already-in-use).") {
                                            toast({
                                                title: "An account for this email already exists. Please login.",
                                                status: "error",
                                                duration: 9000,
                                                isClosable: true,
                                            });
                                        } else {
                                            toast({
                                                title: error.message,
                                                status: "error",
                                                duration: 9000,
                                                isClosable: true,
                                            });
                                        }
                                    });
                            }}
                        >
                            <TextWithLabel
                                label={"Email"}
                                type={"email"}
                                required={true}
                                onChange={({ target: { value } }) => setEmail(value)}
                            ></TextWithLabel>
                            <TextWithLabel
                                label={"Password"}
                                type={"password"}
                                required={true}
                                onChange={({ target: { value } }) => setPassword(value)}
                            ></TextWithLabel>

                            <p className={authStyles.forgotPasswordLabel}>
                                {labels.DISPLAY_FORGOT_PASSWORD && <a onClick={() => toggleModal()}>Forgot Password</a>}
                            </p>

                            <button className={authStyles.submitButton} type="submit">
                                {labels.SUBMIT_BUTTON_TEXT}
                            </button>
                            <p className={authStyles.redirectionCTA}>
                                {labels.REDIRECT_PROMPT}{" "}
                                <Link href={labels.REDIRECT_PROMPT_LINK_URL}>
                                    <a className={"underline"}>{labels.REDIRECT_PROMPT_LINK_TEXT}</a>
                                </Link>
                            </p>
                        </form>
                    </div>
                    <Modal
                        dismiss={() => {
                            toggleModal();
                        }}
                        show={showModal}
                    >
                        <div>
                            <div className={"sm:w-96 w-full"}>
                                <form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        _resetPassword();
                                    }}
                                >
                                    <div className="grid place-items-end">
                                        <TextWithLabel
                                            label="Enter email to recieve password reset link"
                                            onChange={({ target: { value } }) => setForgotEmail(value)}
                                        />
                                        <button
                                            className={[commonStyles.primaryButton, "mt-5"].join(" ")}
                                            type="submit"
                                        >
                                            Forgot Password
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Modal>
                    <Modal
                        dismiss={() => {
                            setShowNoteModal(false);
                        }}
                        show={showNoteModal}
                    >
                        <div>
                            <div className={"sm:w-96 w-full"}>
                                <div className="grid gap-y-5">
                                    <p>
                                        It looks like there’s already an account associated with your Facebook email
                                        address.
                                    </p>
                                    <p>
                                        To connect your Facebook account, log into your Vacayou account with your
                                        username and password and click the “Connect to Facebook” option in your account
                                        settings.
                                    </p>
                                    <p>
                                        If you do not know your password, please select the “forgot password” link to
                                        get a password reset email.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
            </>
        );
    }
}

import commonStyles from "../../../styles/Common.module.scss";

interface ModalProps {
    children: React.ReactNode;
    show?: boolean;
    dismiss?: (Function) => void;
}

const Modal = ({ children, dismiss = () => null, show = true }) => {
    const _dismiss = () => {
        dismiss();
    };
    return (
        <>
            {show && (
                <div className={commonStyles.modalWrapper}>
                    <div>
                        <div>
                            {children}
                            <i
                                className={"fas fa-times"}
                                onClick={(e) => _dismiss()}
                            ></i>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Modal;

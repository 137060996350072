import { ChangeEvent } from "react";
import { useUID } from "react-uid";
import styles from "../../../styles/Common.module.scss";

interface TextWithLabelProps {
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    label: string;
    error?: string;
    className?: string;
    value?: string;
    children?: React.ReactNode;
    type?: string;
    required?: boolean;
}

export default function TextWithLabel({
    label,
    error = null,
    className = null,
    value,
    onChange = (event: ChangeEvent<HTMLInputElement>) => null,
    children = null,
    type = "text",
    required = false,
}: TextWithLabelProps) {
    const id = `TextWithLabel-${useUID()}`;
    const FOCUS_STATE = {
        error: "shadow-textBoxError focus:ring-vacayou-red focus:shadow-textBoxError bg-vacayou-input-fill",
        default: "",
    };
    return (
        <div className={styles.textWithLabel + (className ? " " + className : "")}>
            <label htmlFor={id}>{label}</label>
            <input
                type={type}
                name={id}
                className={error ? FOCUS_STATE.error : FOCUS_STATE.default}
                defaultValue={value}
                required={required}
                onChange={onChange}
            />
            {error !== null && <span className="text-vacayou-red">{error}</span>}
            {children !== null && children}
        </div>
    );
}
